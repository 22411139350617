var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-full -mt-2"},[_c('form-edit',{attrs:{"value":{ id: _vm.personal.id, fullName: _vm.fullName },"controls":true,"link-to":((_vm.personal.id) + "/edit"),"action":{
        show: true,
        resource: ("personal/" + (_vm.personal.id) + "/"),
        component: 'remove-dialog',
        redirect: '/personal',
        title: 'Personal löschen',
        message: ("Sind Sie sicher, dass Sie " + (this.fullName) + " fortfahren wollen?"),
        maxWidth: 'w-1/4',
        type: 'delete',
      },"permission":{
        module: 'Personal',
        subModule: 'Personal',
        childModule: 'Personal',
        operation: 'edit',
        options: ['All', 'Only own personal data', 'Only own unit data'],
      }}}),_c('div',{staticClass:"w-full flex space-x-2 border bg-white rounded-sm p-2 min-h-96"},[(_vm.personal && _vm.personal.details)?_c('div',{staticClass:"w-full"},[_c('tab-card',{attrs:{"data":_vm.personal.details},model:{value:(_vm.reset),callback:function ($$v) {_vm.reset=$$v},expression:"reset"}})],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }