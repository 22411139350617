<template>
  <div>
    <div class="w-full -mt-2">
      <form-edit
        :value="{ id: personal.id, fullName }"
        :controls="true"
        :link-to="`${personal.id}/edit`"
        :action="{
          show: true,
          resource: `personal/${personal.id}/`,
          component: 'remove-dialog',
          redirect: '/personal',
          title: 'Personal löschen',
          message: `Sind Sie sicher, dass Sie ${this.fullName} fortfahren wollen?`,
          maxWidth: 'w-1/4',
          type: 'delete',
        }"
        :permission="{
          module: 'Personal',
          subModule: 'Personal',
          childModule: 'Personal',
          operation: 'edit',
          options: ['All', 'Only own personal data', 'Only own unit data'],
        }"
      />
      <div
        class="w-full flex space-x-2 border bg-white rounded-sm p-2 min-h-96"
      >
        <div v-if="personal && personal.details" class="w-full">
          <tab-card :data="personal.details" v-model="reset" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { getOne, deleteOne } from '@/services/personal'

export default {
  name: 'personal-view',
  props: ['value'],
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      disabledEdit: true,
      personal: {
        series: [],
      },
      dialog: {},
      reset: false,
      breadcrumbs: {
        title: [{ crumb: 'Personal', link: '/personal' }],
        actions: {
          show: true,
          title: this.can('Personal', 'Personal', 'Personal', 'edit', ['All'])
            ? 'Personal erfassen'
            : null,
          component: 'new-personal',
          maxWidth: 'w-2xl',
          resource: `personal/${this.id}/`,
          redirect: `personal`,
        },
      },
    }
  },
  mounted() {
    this.init()

    this.$bus.$off('reload-personal')
    this.$bus.$off('personal-download-evaluation')
    this.$bus.$on('reload-personal', this.init)
    this.$bus.$on('personal-download-evaluation', this.downloadEvaluation)
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)

      getOne(this.id).then(response => {
        if (response.status === 200) {
          this.personal = response.data

          let index = 0
          let oldRphId = 0

          this.personal[
            'series'
          ] = this.personal.performance_evaluation_statistics.chart_data.sort(
            (a, b) => {
              a = _.first(_.keys(a))
                .split('.')
                .reverse()
                .join('')
              b = _.first(_.keys(b))
                .split('.')
                .reverse()
                .join('')

              return a > b ? 1 : a < b ? -1 : 0
            }
          )

          this.personal['series'] = _.map(
            this.personal.performance_evaluation_statistics.chart_data,
            (cd, index) => {
              const data = [
                ...Array.from(
                  {
                    length: this.personal.performance_evaluation_statistics
                      .chart_data.length,
                  },
                  () => 0
                ),
              ]
              data[index] = _.first(_.values(cd))
              return {
                name: _.first(_.keys(cd)),
                data,
              }
            }
          )

          this.personal.roleprofile = _.map(this.personal.roleprofile, rph => {
            if (oldRphId !== rph.id) {
              oldRphId = rph.id
              index = 0
            }

            const employes = _.map(
              _.filter(rph.employes, employe => {
                if (
                  employe.personal.toString() === this.id.toString() &&
                  !employe.archived
                )
                  return employe
              })
            )[index]

            ++index

            return { ...rph, employes }
          })

          index = 0
          oldRphId = 0

          this.personal.roleprofile_historie = _.map(
            this.personal.roleprofile_historie,
            rph => {
              if (oldRphId !== rph.id) {
                oldRphId = rph.id
                index = 0
              }

              const employes = _.map(
                _.filter(rph.employes, employe => {
                  if (
                    employe.personal.toString() === this.id.toString() &&
                    employe.archived
                  )
                    return employe
                })
              )[index]

              ++index

              return { ...rph, employes, archived: true, hideDot: true }
            }
          )

          this.personal = {
            ...this.personal,
            details: {
              tabs: [
                // Übersicht
                {
                  caption: 'Übersicht',
                  component: 'CardTable',
                  data: {
                    card_width: [
                      'w-full md:w-1/2',
                      'w-full md:w-1/2',
                      'w-full md:w-1/2',
                      'w-full md:w-1/2',
                      'w-full md:w-1/2',
                    ],
                    col_width: ['w-5/12', 'w-7/12'],
                    profile: {
                      first_name: this.personal.first_name || '',
                      last_name: this.personal.last_name || '',
                      personalnummer: this.personal.personal_number,
                      picture: this.personal.personal_avatar,
                      civil_status: this.personal.civil_status,
                      postcode: this.personal.postcode
                        ? this.personal.postcode
                        : '-',
                      performance_evaluation_statistics:
                        this.personal.performance_evaluation_statistics &&
                        this.personal.performance_evaluation_statistics
                          .chart_data,
                      rating_scales: this.personal
                        .performance_evaluation_statistics
                        ? this.personal.performance_evaluation_statistics
                            .rating_scale
                        : [],
                      place: this.personal.place,
                      initial: this.personal.initial.split('').join(' '),
                    },
                    cards: [
                      {
                        title: 'Kontakt Information',
                        picture:
                          this.profile.avatar || this.profile.initial || null,
                        show: true,
                        cols: [
                          {
                            label: 'E-Mail',
                            value: this.personal.email || '-',
                          },
                          {
                            label: 'E-Mail (alternativ)',
                            value: this.personal.email_alternative || '-',
                          },
                          {
                            label: 'Geschäft Telefon',
                            value: this.personal.business_phone || '-',
                          },
                          {
                            label: 'Geschäft Mobile',
                            value: this.personal.business_mobile || '-',
                          },
                          {
                            label: 'Privat Telefon',
                            value: this.personal.private_phone || '-',
                          },
                          {
                            label: 'Privat Mobile',
                            value: this.personal.private_mobile || '-',
                          },
                        ],
                      },
                      {
                        title: 'Leistungsbeurteilungen',
                        component: 'apexchart',
                        options: {
                          chart: {
                            height: 450,
                            type: 'scatter',
                            toolbar: {
                              show: false,
                            },
                          },
                          tooltip: {
                            y: {
                              formatter: (
                                value
                                // { series, seriesIndex, dataPointIndex, w }
                              ) => value,
                            },
                          },
                          legend: {
                            show: false,
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          fill: {
                            opacity: 0.8,
                          },
                          xaxis: {
                            categories: _.map(this.personal.series, 'name'),
                          },
                          yaxis: {
                            tickAmount:
                              this.personal.performance_evaluation_statistics
                                .rating_scale.length - 1,
                            min: 1,
                            max: this.personal.performance_evaluation_statistics
                              .rating_scale.length,
                            labels: {
                              formatter: (value, seriesIndex) => {
                                return (
                                  Number.isInteger(seriesIndex) &&
                                  this.personal
                                    .performance_evaluation_statistics
                                    .rating_scale[seriesIndex].name
                                )
                              },
                              offsetX: -25,
                            },
                          },
                        },
                        series: this.personal.series,
                      },
                      {
                        title: 'Allgemeine Information',
                        cols: [
                          {
                            label: 'Kürzel',
                            value: this.personal.initial || '-',
                          },
                          {
                            label: 'Personalnummer',
                            value: this.personal.personal_number || '-',
                          },
                          {
                            label: 'Geburtsdatum',
                            value: this.dateOrDash(this.personal.birth_date),
                          },
                          {
                            label: 'Adresse',
                            value: this.personal.address || '-',
                          },
                          {
                            label: 'Postleitzahl',
                            value:
                              this.personal.postcode !== '0'
                                ? this.personal.postcode
                                : '-',
                          },
                          {
                            label: 'Ort',
                            value: this.personal.place || '-',
                          },
                          {
                            label: 'Heimatort',
                            value: this.personal.hometown || '-',
                          },
                          {
                            label: 'Position',
                            value: this.personal.position || '-',
                          },
                          {
                            label: 'Eintrittsdatum',
                            value: this.dateOrDash(this.personal.start_date),
                          },

                          {
                            label: 'Austrittsdatum',
                            value: this.dateOrDash(this.personal.leaving_date),
                          },
                          {
                            label: 'AHV Nummer',
                            value: this.personal.si_number || '-',
                          },
                          {
                            label: 'Zivilstand',
                            value: this.personal.civil_status || '-',
                          },
                          {
                            label: 'IBAN',
                            value: this.personal.bank_account || '-',
                          },
                          {
                            label: 'Bank',
                            value: this.personal.bank_institution || '-',
                          },
                        ],
                      },
                      {
                        title: 'Weitere Information',
                        cols: [
                          {
                            label: 'Schlüssel Nr.',
                            value: this.personal.key_no || '-',
                          },
                          {
                            label: 'Bemerkung',
                            value: this.personal.note || '-',
                          },
                        ],
                      },
                      {
                        title: 'Notfall Kontakt Information',
                        cols: [
                          {
                            label: 'Vorname',
                            value: this.personal.emergency_first_name || '-',
                          },
                          {
                            label: 'Nachname',
                            value: this.personal.emergency_last_name || '-',
                          },
                          {
                            label: 'Telefon',
                            value: this.personal.emergency_phone || '-',
                          },
                          {
                            label: 'E-Mail',
                            value: this.personal.emergency_email || '-',
                          },
                          {
                            label: 'Notiz',
                            value: this.personal.emergency_note || '-',
                          },
                        ],
                      },
                    ],
                  },
                },
                // Rollenprofile
                {
                  caption: 'Rollenprofile',
                  component: 'data-repeater',
                  permission: {
                    module: 'Personal',
                    subModule: 'Personal',
                    childModule: 'Personal',
                    operation: 'edit',
                    options: ['All'],
                  },
                  addBtn: 'Rollenprofile erstellen',
                  resource: '',
                  parent_id: this.personal.id,
                  icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 30 30"><path d="M 3 5 L 3 9 L 7 9 L 7 5 L 3 5 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 3 13 L 3 17 L 7 17 L 7 13 L 3 13 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 3 21 L 3 25 L 7 25 L 7 21 L 3 21 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z"></path></svg>`,
                  callback: () => {
                    this.$bus.$emit('show-modal', {
                      show: true,
                      title: 'Rollenprofile erstellen',
                      component: 'new-roleprofile',
                      resource:
                        '/organisation/unit/roleprofile/?no_pagination=true',
                      maxWidth: 'w-1/3',
                      event: 'reload-personal',
                      parent_id: this.personal.id,
                    })
                  },
                  actions: {
                    list: ['edit', 'delete'],
                    callback: state => {
                      this.$bus.$emit('show-modal', {
                        show: true,
                        title:
                          state.type === 'delete'
                            ? 'Rollenprofile entfernen'
                            : 'Rollenprofile bearbeiten',
                        component:
                          state.type === 'delete'
                            ? 'remove-dialog'
                            : 'new-roleprofile',
                        request: state.type,
                        message: `Möchten Sie wirklich den Rollenprofile ${state.payload.name} entfernen?`,
                        resource: `/organisation/unit/employe/${state.payload.employes.id}/`,
                        data: state.payload,
                        parent_id: this.personal.id,
                        maxWidth: 'w-1/3',
                        event: 'reload-personal',
                        type: state.type,
                      })
                    },
                  },
                  columns: [
                    {
                      label: 'Rollenprofile',
                      field: ['name'],
                      link: 'id',
                      type: 'link',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '25',
                      linkTo: 'organisation/unit/roleprofile',
                    },
                    {
                      label: 'Arbeitspensum',
                      field: ['employes.workload'],
                      headerAlign: 'left',
                      type: 'text',
                      width: '20',
                      postfix: '%',
                    },
                    {
                      label: 'Startdatum',
                      field: ['employes.start_date'],
                      headerAlign: 'left',
                      type: 'date',
                      width: '15',
                      postfix: '%',
                    },
                    {
                      label: 'Enddatum',
                      field: ['employes.leaving_date'],
                      headerAlign: 'left',
                      type: 'date',
                      width: '15',
                      postfix: '%',
                    },
                    {
                      label: 'Status',
                      field: ['archived'],
                      headerAlign: 'left',
                      bool: 'bool',
                      width: '5',
                      postfix: '',
                    },
                    {
                      label: '',
                      field: ['id'],
                      type: 'checkbox',
                      width: '2',
                    },
                  ],
                  rows: this.personal.roleprofile.concat(
                    this.personal.roleprofile_historie
                  ),
                },
                // Ziele
                {
                  caption: 'Ziele',
                  component: 'data-repeater',
                  permission: {
                    module: 'Personal',
                    subModule: 'Personal',
                    childModule: 'Personal',
                    operation: 'edit',
                    options: ['All'],
                  },
                  addBtn: 'Ziel erstellen',
                  resource: '',
                  parent_id: this.personal.id,
                  icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 30 30"><path d="M 3 5 L 3 9 L 7 9 L 7 5 L 3 5 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 3 13 L 3 17 L 7 17 L 7 13 L 3 13 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 3 21 L 3 25 L 7 25 L 7 21 L 3 21 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z"></path></svg>`,
                  callback: () => {
                    this.$bus.$emit('show-modal', {
                      show: true,
                      title: 'Ziel erstellen',
                      component: 'new-goal',
                      resource: '/personal/goal/',
                      maxWidth: 'w-1/3',
                      event: 'reload-personal',
                      parent_id: this.personal.id,
                    })
                  },
                  actions: {
                    list: ['edit', 'delete'],
                    callback: state => {
                      this.$bus.$emit('show-modal', {
                        show: true,
                        title:
                          state.type === 'delete'
                            ? 'Ziele entfernen'
                            : 'Ziele bearbeiten',
                        component:
                          state.type === 'delete'
                            ? 'remove-dialog'
                            : 'new-goal',
                        request: state.type,
                        message: `Möchten Sie wirklich den Ziele ${state.payload.name} entfernen?`,
                        resource: `/personal/goal/${state.payload.id}/`,
                        data: state.payload,
                        parent_id: this.personal.id,
                        maxWidth: 'w-1/3',
                        event: 'reload-personal',
                        type: state.type,
                      })
                    },
                  },
                  columns: [
                    {
                      label: 'Ziele',
                      field: ['name'],
                      type: 'text',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '25',
                      postfix: '',
                    },
                    {
                      label: 'Beschreibung',
                      field: ['description'],
                      type: 'text',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '75',
                      postfix: '',
                    },
                    { label: '', field: ['id'], type: 'checkbox', width: '10' },
                  ],
                  rows: this.personal.goals,
                },
                // Leistungsbeurteilungen
                {
                  caption: 'Leistungsbeurteilungen',
                  component: 'DataRepeater',
                  columns: [
                    {
                      label: 'Durchgeführt mit',
                      field: ['supervisor.personal.full_name'],
                      type: 'text',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '20',
                      postfix: '',
                    },
                    {
                      label: 'Status',
                      field: ['status'],
                      type: 'status',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '20',
                      postfix: '',
                    },
                    {
                      label: 'Abgeschlossen',
                      field: ['completed_at'],
                      type: 'date',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '20',
                      postfix: '',
                    },
                    {
                      label: 'Vorlage',
                      field: ['template.name'],
                      type: 'text',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '20',
                      postfix: '',
                    },
                    {
                      label: '',
                      field: ['link_to'],
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '20',
                      postfix: '',
                      linkTo: `performance-evaluation/pdf`,
                      type: 'link',
                      link: 'id',
                      event: 'personal-download-evaluation',
                    },
                  ],
                  rows: _.map(this.personal.performance_evaluations, pe => ({
                    ...pe,
                    type: 'pdf',
                    link_to: 'PDF erstellen',
                    completed_at: this.moment(pe.completed_at, 'DD-MM-YYYY'),
                  })),
                },
                // Entwicklungsmassnahmen
                {
                  caption: 'Entwicklungsmassnahmen',
                  component: 'repeater',
                  data: [
                    {
                      caption: 'Aktiv',
                      component: 'data-repeater',
                      permission: {
                        module: 'Personal',
                        subModule: 'Personal',
                        childModule: 'Personal',
                        operation: 'edit',
                        options: ['All'],
                      },
                      addBtn: 'Entwicklungsmassnahmen erstellen',
                      resource: '',
                      parent_id: this.personal.id,
                      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 30 30"><path d="M 3 5 L 3 9 L 7 9 L 7 5 L 3 5 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 3 13 L 3 17 L 7 17 L 7 13 L 3 13 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 3 21 L 3 25 L 7 25 L 7 21 L 3 21 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z"></path></svg>`,
                      callback: () => {
                        this.$bus.$emit('show-modal', {
                          show: true,
                          title: 'Entwicklungsmass erstellen',
                          component: 'new-developmentaction',
                          resource: '/personal/developmentaction/',
                          maxWidth: 'w-1/3',
                          event: 'reload-developmentaction',
                          parent_id: this.personal.id,
                        })
                      },
                      actions: {
                        list: ['edit', 'delete'],
                        callback: state => {
                          this.$bus.$emit('show-modal', {
                            show: true,
                            title:
                              state.type === 'delete'
                                ? 'Entwicklungsmass entfernen'
                                : 'Entwicklungsmass bearbeiten',
                            component:
                              state.type === 'delete'
                                ? 'remove-dialog'
                                : 'new-developmentaction',
                            request: state.type,
                            message: `Möchten Sie wirklich den Entwicklungsmass entfernen?`,
                            resource: `/personal/developmentaction/${state.payload.id}`,
                            data: state.payload,
                            parent_id: this.personal.id,
                            maxWidth: 'w-1/3',
                            event: 'reload-personal',
                            type: state.type,
                          })
                        },
                      },
                      columns: [
                        {
                          label: 'Massnahme',
                          field: ['title'],
                          type: 'text',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '20',
                          postfix: '',
                        },
                        {
                          label: 'Beschreibung',
                          field: ['description'],
                          type: 'text',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '40',
                          postfix: '',
                        },
                        {
                          label: 'Geplante Umsetzung',
                          field: ['realization'],
                          type: 'date',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '20',
                          postfix: '',
                        },
                        {
                          label: 'Budget',
                          field: ['cost_company'],
                          type: 'text',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '20',
                          postfix: '',
                        },
                        {
                          label: '',
                          field: ['id'],
                          type: 'checkbox',
                          width: '2',
                        },
                      ],
                      rows: this.personal.developmentactions.active,
                    },
                    {
                      caption: 'Historie',
                      component: 'data-repeater',
                      permission: {
                        module: 'Personal',
                        subModule: 'Personal',
                        childModule: 'Personal',
                        operation: 'edit',
                        options: ['All'],
                      },
                      // addBtn: 'Entwicklungsmassnahme erstellen',
                      resource: '',
                      parent_id: this.personal.id,
                      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 30 30"><path d="M 3 5 L 3 9 L 7 9 L 7 5 L 3 5 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 3 13 L 3 17 L 7 17 L 7 13 L 3 13 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 3 21 L 3 25 L 7 25 L 7 21 L 3 21 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z"></path></svg>`,
                      callback: () => {
                        this.$bus.$emit('show-modal', {
                          show: true,
                          title: 'Entwicklungsmassnahme erstellen',
                          component: 'new-developmentaction',
                          resource: '/personal/developmentaction/',
                          maxWidth: 'w-1/3',
                          event: 'reload-developmentaction',
                          parent_id: this.personal.id,
                        })
                      },
                      actions: {
                        list: ['edit', 'delete'],
                        callback: state => {
                          this.$bus.$emit('show-modal', {
                            show: true,
                            title:
                              state.type === 'delete'
                                ? 'Entwicklungsmass entfernen'
                                : 'Entwicklungsmass bearbeiten',
                            component:
                              state.type === 'delete'
                                ? 'remove-dialog'
                                : 'new-developmentaction',
                            request: state.type,
                            message: `Möchten Sie wirklich den Entwicklungsmass entfernen?`,
                            resource: `/personal/developmentaction/${state.payload.id}`,
                            data: state.payload,
                            parent_id: this.personal.id,
                            maxWidth: 'w-1/3',
                            event: 'reload-personal',
                            type: state.type,
                          })
                        },
                      },
                      columns: [
                        {
                          label: 'Massnahme',
                          field: ['title'],
                          type: 'text',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '20',
                          postfix: '',
                        },
                        {
                          label: 'Beschreibung',
                          field: ['description'],
                          type: 'text',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '40',
                          postfix: '',
                        },
                        {
                          label: 'Geplante Umsetzung',
                          field: ['realization'],
                          type: 'date',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '20',
                          postfix: '',
                        },
                        {
                          label: 'Budget',
                          field: ['cost_company'],
                          type: 'text',
                          headerClass: 'class-in-header second-class',
                          headerAlign: 'left',
                          width: '20',
                          postfix: '',
                        },
                        // {
                        //   label: '',
                        //   field: ['id'],
                        //   type: 'checkbox',
                        //   width: '2',
                        // },
                      ],
                      rows: this.personal.developmentactions.historie,
                    },
                  ],
                },
                // Dokumente
                {
                  caption: 'Dokumente',
                  component: 'data-repeater',
                  permission: {
                    module: 'Personal',
                    subModule: 'Personal',
                    childModule: 'Dokumente',
                    operation: 'edit',
                    options: [
                      'All',
                      'Only own personal data',
                      'Only own unit data',
                    ],
                  },
                  addBtn: 'Dokument hochladen',
                  resource: '',
                  parent_id: this.personal.id,
                  icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 30 30"><path d="M 3 5 L 3 9 L 7 9 L 7 5 L 3 5 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 3 13 L 3 17 L 7 17 L 7 13 L 3 13 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 3 21 L 3 25 L 7 25 L 7 21 L 3 21 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z"></path></svg>`,
                  callback: () => {
                    this.$bus.$emit('show-modal', {
                      show: true,
                      title: 'Dokument hochladen',
                      component: 'new-document-upload',
                      resource: '/personal/document/',
                      maxWidth: 'w-1/3',
                      event: 'reload-personal',
                      parent_id: this.personal.id,
                    })
                  },
                  actions: {
                    list: ['edit', 'delete'],
                    callback: state => {
                      this.$bus.$emit('show-modal', {
                        show: true,
                        title:
                          state.type === 'delete'
                            ? 'Dokument entfernen'
                            : 'Dokument bearbeiten',
                        component:
                          state.type === 'delete'
                            ? 'remove-dialog'
                            : 'new-document-upload',
                        request: state.type,
                        message: `Möchten Sie wirklich das Dokument ${state.payload.name} entfernen?`,
                        resource: `/personal/document/${state.payload.id}/`,
                        data: state.payload,
                        parent_id: this.personal.id,
                        maxWidth: 'w-1/3',
                        event: 'reload-personal',
                        type: state.type,
                      })
                    },
                  },
                  columns: [
                    {
                      label: 'Kategorie',
                      prefix: '',
                      postfix: '',
                      field: ['categorie'],
                      type: 'text',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '25',
                    },
                    {
                      label: 'Dokument',
                      field: 'file_name',
                      link: 'document',
                      type: 'preview',
                      headerClass: 'class-in-header second-class',
                      headerAlign: 'left',
                      width: '75',
                      postfix: '',
                    },
                    {
                      label: '',
                      field: ['id'],
                      type: 'checkbox',
                      width: '10',
                    },
                  ],
                  rows: this.personal.documents,
                },
              ],
            },
          }
        }
      })
    },
  },
  computed: {
    fullName() {
      return `${this.personal.first_name || ''} ${this.personal.last_name ||
        ''}`
    },
  },
  watch: {
    dialog: function(n) {
      if (n.delete) {
        deleteOne(this.id).then(response => {
          if (response.status === 200) {
            this.$router.push('/personal')
          }
        })
      }
    },
    '$route.params.id': function(n, o) {
      if (n !== o) {
        this.id = n
        this.init()
        this.reset = true
      }
    },
  },
}
</script>

<style></style>
